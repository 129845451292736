<template>
    <div class="practical-train-operate">
        <head-path :list="['直播实训题管理', '实训试卷管理', '创建实训试卷']"></head-path>
        <div class="create-paper">
            <div class="title-box">创建试卷</div>
            <div class="form-box">
                <pack-el-form
                    ref="packElForm"
                    :form-config="formConfig"
                    :rules="rules"
                    :params.sync="operateParams"
                    :input-width="380"
                    :is-center="false"
                ></pack-el-form>
            </div>
        </div>
        <div class="award-flow-set">
            <div class="title-box">奖励流量池设置</div>
            <div class="form-box">
                <el-form class="el-form" :model="operateParams" :rules="rules" ref="ruleForm">
                    <el-form-item>
                        <div class="content">
                            <el-form-item class="label-box">
                                <div class="container">
                                    <div class="level" style="visibility: hidden">Q</div>
                                    <div class="on-line">达到的在线人数</div>
                                    <div class="award">奖励人数</div>
                                </div>
                            </el-form-item>
                            <el-form-item class="input-box" prop="flow_pool" ref="flowPool">
                                <div class="container" v-for="(item, index) in operateParams.flow_pool" :key="index">
                                    <div class="level">{{ levelAwards[index] }}</div>
                                    <el-form :model="item" :rules="childRules" ref="childForm">
                                        <el-form-item class="on-line" prop="onlive_num">
                                            <!-- <div class="on-line"> -->
                                            <el-input
                                                type="tel"
                                                :value="item.onlive_num"
                                                placeholder="请填写在线人数"
                                                @focus="clearCheckFlowPool"
                                                @input="(value) => setFlowPool(value, item, 'onlive_num')"
                                            ></el-input>
                                            <!-- </div> -->
                                        </el-form-item>
                                        <el-form-item prop="award_num">
                                            <div class="award">
                                                <el-input
                                                    type="tel"
                                                    :value="item.award_num"
                                                    placeholder="请填写奖励"
                                                    @focus="clearCheckFlowPool"
                                                    @input="(value) => setFlowPool(value, item, 'award_num')"
                                                ></el-input>
                                            </div>
                                        </el-form-item>
                                    </el-form>
                                </div>
                            </el-form-item>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="footer">
            <el-button @click="back">返回</el-button>
            <el-button type="primary" @click="operate">创建</el-button>
        </div>
    </div>
</template>

<script>
import HeadPath from "components/common/HeadPath";
import PackElForm from "components/common/PackElForm";
import { showScriptListData, operatePracticalTrain, getPracticalTrainData, adList } from "@/utils/apis";

export default {
    paper_name: "PracticalTrainOperate",
    data() {
        return {
            operateParams: {
                paper_name: "",
                script_id: "",
                advert_id: "",
                /**keys: onlive_num, award_num */
                flow_pool: [{}, {}, {}, {}, {}],
            },
            rules: {
                paper_name: [{ required: true, message: "请填写试卷名称", trigger: "blur" }],
                script_id: [{ required: true, message: "请选择脚本", trigger: "change" }],
                advert_id: [{ required: true, message: "请选择广告", trigger: "change" }],
            },
            childRules: {
                onlive_num: [{ required: true, message: "请填写在线人数", trigger: "blur" }],
                award_num: [{ required: true, message: "请填写奖励人数", trigger: "blur" }],
            },
            scriptData: [],
            adData: [],
            levelAwards: ["A", "B", "C", "D", "E"],
            labelList: ["", "奖励的在线人数", "奖励人数"],
        };
    },
    components: {
        HeadPath,
        PackElForm,
    },
    computed: {
        formConfig() {
            return [
                {
                    label: "创建名称",
                    prop: "paper_name",
                    placeholder: "2-20个字符",
                    max: 20,
                },
                {
                    label: "脚本",
                    type: "select",
                    prop: "script_id",
                    placeholder: "请选择脚本",
                    options: this.scriptData,
                },
                {
                    label: "广告投放",
                    type: "select",
                    prop: "advert_id",
                    placeholder: "请选择广告",
                    options: this.adData,
                },
            ];
        },
    },
    methods: {
        async init() {
            this.getScriptList();
            this.getADList();
            this.operateParams.id && this.editPaper();
        },
        async getScriptList() {
            const res = await showScriptListData({ limit: "all" });
            this.scriptData = res.data.map((item) => ({ label: item.script_name, value: item.id }));
        },
        async getADList() {
            const res = await adList({ paging: 0 });
            this.adData = res.data.list.map((item) => ({ label: item.advertising_name, value: item.advertising_id }));
        },
        operate() {
            const { packElForm, ruleForm } = this.$refs;
            const pondValid = [];
            this.$refs.childForm.forEach((item, index) => item.validate((valid) => (pondValid[index] = valid)));
            const allValid = pondValid.every((item) => item);
            packElForm.validate(() =>
                ruleForm.validate(async (valid) => {
                    if (!valid || !allValid) return false;
                    const res = await operatePracticalTrain(this.operateParams);
                    this.back();
                    this.$message.success(res.msg);
                })
            );
        },
        async editPaper() {
            const res = await getPracticalTrainData({ id: this.operateParams.id });
            Object.assign(this.operateParams, res.data);
        },
        setFlowPool(value, item, prop) {
            if (value > 0 || value === "") {
                this.$set(item, prop, value > 1000000 ? 1000000 : value);
            }
        },
        clearCheckFlowPool() {
            if (this.rules.flow_pool) {
                this.$delete(this.rules, "flow_pool");
                this.$refs.flowPool.clearValidate();
            }
        },
        back() {
            this.$router.back();
        },
    },
    mounted() {
        const id = this.$route.query.id;
        if (id) {
            this.operateParams.id = id;
        }
        this.init();
    },
};
</script>

<style scoped lang="scss">
.practical-train-operate {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    padding: 30px;
    font-size: 14px;
    color: #1b162a;
    .title-box {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #060111;
        font-weight: 700;
        &::before {
            content: "";
            display: block;
            width: 6px;
            height: 12px;
            margin-right: 8px;
            background-color: #2821fc;
            border-radius: 3px;
        }
    }
    .form-box {
        margin: 32px 20px 18px;
        .content {
            display: flex;
            .container {
                .level {
                    margin-bottom: 21px;
                    font-size: 16px;
                    color: #332d42;
                    text-align: center;
                    line-height: normal;
                    white-space: nowrap;
                }
                .on-line,
                .award {
                    width: 100%;
                    box-sizing: border-box;
                    margin-bottom: 23px;
                    white-space: nowrap;
                }
                .award {
                    margin-bottom: 0;
                }
            }
            .label-box {
                width: 107px;
                .on-line,
                .award {
                    margin-left: 0;
                    text-align: end;
                }
                .on-line::before,
                .award::before {
                    content: "*";
                    margin-right: 3px;
                    color: #ff3e6c;
                }
            }
            .input-box {
                flex: 1;
                ::v-deep .el-form-item__content {
                    display: flex;
                    .container {
                        box-sizing: border-box;
                        flex: 1;
                        max-width: 250px;
                        margin-left: 9px;
                    }
                    .el-form-item__error {
                        margin-left: 23px;
                    }
                }
            }
        }
    }
    .award-flow-set {
        flex: 1;
    }
    .footer {
        margin-top: 20px;
        text-align: center;
        ::v-deep .el-button {
            width: 76px;
        }
    }
}

::v-deep .el-form {
    .el-form-item__label {
        white-space: nowrap;
        color: #1b162a;
    }
    /* 谷歌 */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
    }
    /* 火狐 */
    input {
        -moz-appearance: textfield;
    }
}
</style>
